import request from '@/utils/request'

export function getCloudDataList(params) {
  return request({
    url: '/serveManagement/getCloudDataList',
    method: 'get',
    params,
  })
}

export function callTodayCloudPackageQueryNum(params) {
  return request({
    url: '/serveManagement/callTodayCloudPackageQueryNum',
    method: 'get',
    params,
  })
}
